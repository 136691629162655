<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                :isGkkpPaid="true"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadRepPaid="downloadRepPaid"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header 
                    v-show="currTabCategoryMode" 
                    ref="budgetHeader" 
                    :form="form" 
                    :yearReq="true" 
                    :openDisabled="openDisabled" 
                    :currTabName="currTabName"
                    @setProgress="setProgress"
                    @chgData="changeHeader" 
                ></budget-header>
                <p v-if="!currTabCategoryMode" class="title">{{ getCommonText('decoding') }} {{ decodeTabAdditionalTitle }}. {{ currTabCategoryName }}</p>
            </div>
            <div class="right-content">
                <template v-if="currTabCategoryMode">
                    <c-budget-forms-data-transfer
                        v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                        :header="header"
                        :lng="lng"
                        :getDataTransferText="getDataTransferText"
                        :getErrText="getErrText"
                        :makeToast="makeToast"
                        @reloadTable="loadCategoryData"
                    />
                    <div class="filter-actions  filter-actions-flex">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="isPaidTab ?  budgetFormPaid : budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            :isPaidTab="isPaidTab"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="onEnterCategoryName" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    </div>
                </template>
                <template v-else>
                    <b-button variant="light" @click="onGoBackBtnClicked">
                        <i class="icon icon-keyboard icon-rotate-180"></i> {{ getCommonText('back_to_list') }}
                    </b-button>
                    <b-button :style="{ 'minWidth': '110px' }" v-if="variantAttribute" variant="primary" @click="onAddGoodBtnClicked">
                        <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                    </b-button>
                    <b-button
                        :style="{ 'minWidth': '110px' }" 
                        :disabled="!dataChanged || goodsAreSaving"
                        variant="success"
                        @click="onSaveGoodBtnClicked">
                        {{ getCommonText('save') }}
                    </b-button>
                </template>
            </div>
        </div>

        <b-tabs 
            active-nav-item-class="budget-form_tabs_active_tab"
            nav-class="budget-form_tabs" 
            v-model="activeTabIndex"
        >
            <breadcrumbs-filter v-show="isBreadcrumbsShown" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>

            <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
            ></b-progress>
            <b-tab 
                :title="getCommonText('budget_expense')"
                @click="updateRoute(0)" 
            >
                <!-- Первая вклапдка -->
                <form-02-159-tab1  
                    ref="formTab"
                    v-show="categoryMode"
                    :budgetForm="budgetForm"
                    :deletingCat="deletingCat"
                    :dict="dict"
                    :getCommonText="getCommonText"
                    :getUnitsText="getUnitsText"
                    :isLoad="isLoad"
                    :selectAll="selectAll"
                    :setFormText="setFormText"
                    :total="total"
                    :variantAttribute="variantAttribute"
                    :categoryFieldNames="categoryFieldNames"
                    :lng="lng"
                    @addItem="onEnterCategoryName"
                    @deleteItem="deleteItem"
                    @onAllFilesClick="onAllFilesClick"
                    @setCurrCatName="setCurrCatName"
                    @setSelectAll="setSelectAll"
                    @onEditCatName="onEditCatName"
                ></form-02-159-tab1>

                <!-- Первая вклапдка -->
                <template v-if="!categoryMode">
                    <b-overlay :show="templateTableOverlay" rounded="sm">
                        <form-03149-template1
                            ref="formTemplate"
                            :formCode="form.code"
                            :goodsData="goodsData"
                            :initialGoodsData="initialGoodsData"
                            :ensTruDict="ensTruDict"
                            :variantAttribute="variantAttribute"
                            :currCategory="currCategory"
                            :lang="lang"
                            :ifAllFieldsFilled="ifAllFieldsFilled"
                            :deleteGood="deleteGood"
                            :getUnitName="getUnitName"
                            :ensTruNamePrt="ensTruNamePrt"
                            :ensTruName="ensTruName"
                            :currUnits="currUnits"
                            :descIsLoading="descIsLoading"
                            :parNameLoading="parNameLoading"
                            :allFiles="allFiles"
                            @setTotals="setTotals"
                            @updateDatas="updateDatas"
                            @save="save"
                            @onDataChanged="onDataChanged"
                            @searchName="searchName"
                            @setIsAllDelete="setIsAllDelete"
                            @deleteSeveralGoods="deleteSeveralGoods"
                            @doublesValidationDecodes="doublesValidationDecodes"
                            :triggDownloadFile="triggDownloadFile"
                            :showFileUploadModal="showFileUploadModal"
                            :getEnstruByName="getEnstruByName"
                            :loadCurUnits="loadCurUnits"
                            :goodsAreSaving="goodsAreSaving"
                            :isDoubleDecodeExist="isDoubleDecodeExist"
                        ></form-03149-template1>
                    </b-overlay>
                    <div class="table-add" v-if="variantAttribute">
                        <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                    </div>
                </template>
                <!-- Первая вклапдка -->
                <files-updown ref="filesUpdown"
                    v-show="categoryMode"
                    :header="header"
                    :variant-attribute="variantAttribute"
                    :load="load"
                    :showDeleteLinks="variantAttribute"
                    :showDeleteAll="true"
                    @getFiles="getFiles"
                    @fillFilesList="fillFilesList"
                    @restetFilesList="restetFilesList"
                    @delelteAttachedFile="delelteAttachedFile"
                    @onFilePreview="onFilePreview"
                ></files-updown>

                <modal-all-files-management
                    ref="modalAllFilesManagement"
                    :allFiles="allFiles"
                    @triggDownloadFile="triggDownloadFile"
                    @triggDownloadAllFiles="triggDownloadAllFiles"
                >
                </modal-all-files-management>

                <c-set-b-f-category-name
                    ref="setCatNameModal"
                    :budgetForm="budgetForm"
                    :categoryFieldNames="categoryFieldNames"
                    :lng="lng"
                    :getCommonText="getCommonText"
                    @addItem="addItem"
                    @onCatNameChanged="onCatNameChanged"
                />
            </b-tab>
            <b-tab 
                :title="getCommonText('paid_services')" 
                @click="updateRoute(1)"
            >
                <!-- Вторая вклапдка -->
                <form-02-159-tab1  
                    ref="formTabPaid"
                    v-show="categoryModePaid"
                    :budgetForm="budgetFormPaid"
                    :deletingCat="deletingCat"
                    :dict="dictPaid"
                    :getCommonText="getCommonText"
                    :getUnitsText="getUnitsText"
                    :isLoad="isLoad"
                    :selectAll="selectAllPaid"
                    :setFormText="setFormText"
                    :total="totalPaid"
                    :variantAttribute="variantAttribute"
                    :categoryFieldNames="categoryFieldNames"
                    :lng="lng"
                    @addItem="onEnterCategoryName"
                    @deleteItem="deleteItemPaid"
                    @onAllFilesClick="onAllFilesClickPaid"
                    @setCurrCatName="setCurrCatNamePaid"
                    @setSelectAll="setSelectAll"
                    @onEditCatName="onEditCatName"
                ></form-02-159-tab1>

                <!-- Вторая вклапдка -->
                <template v-if="!categoryModePaid">
                    <b-overlay :show="templateTableOverlay" rounded="sm">
                        <form-03149-template1
                            ref="formTemplatePaid"
                            :formCode="form.code"
                            :goodsData="goodsDataPaid"
                            :initialGoodsData="initialGoodsDataPaid"
                            :ensTruDict="ensTruDict"
                            :variantAttribute="variantAttribute"
                            :currCategory="currCategoryPaid"
                            :lang="lang"
                            :ifAllFieldsFilled="ifAllFieldsFilledPaid"
                            :deleteGood="deleteGoodPaid"
                            :getUnitName="getUnitName"
                            :ensTruNamePrt="ensTruNamePrt"
                            :ensTruName="ensTruName"
                            :currUnits="currUnits"
                            :descIsLoading="descIsLoading"
                            :parNameLoading="parNameLoading"
                            :allFiles="allFilesPaid"
                            @setTotals="setTotals"
                            @updateDatas="updateDatasPaid"
                            @save="savePaid"
                            @onDataChanged="onDataChanged"
                            @searchName="searchName"
                            @setIsAllDelete="setIsAllDeletePaid"
                            @deleteSeveralGoods="deleteSeveralGoodsPaid"
                            @doublesValidationDecodes="doublesValidationDecodes"
                            :triggDownloadFile="triggDownloadFilePaid"
                            :showFileUploadModal="showFileUploadModalPaid"
                            :getEnstruByName="getEnstruByName"
                            :loadCurUnits="loadCurUnits"
                            :goodsAreSaving="goodsAreSaving"
                            :isDoubleDecodeExist="isDoubleDecodeExist"
                        ></form-03149-template1>
                        <div class="table-add" v-if="variantAttribute">
                            <span @click="$refs.formTemplatePaid.addItem()"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                        </div>
                    </b-overlay>
                </template>

                <!-- Вторая вклапдка -->
                <files-updown ref="filesUpdownPaid"
                    v-show="categoryModePaid"
                    :header="header"
                    :variant-attribute="variantAttribute"
                    :load="load"
                    :showDeleteLinks="variantAttribute"
                    :showDeleteAll="true"
                    :isPaidTab="true"
                    @getFiles="getFilesPaid"
                    @fillFilesList="fillFilesListPaid"
                    @restetFilesList="restetFilesListPaid"
                    @delelteAttachedFile="delelteAttachedFilePaid"
                ></files-updown>

                <modal-all-files-management
                    ref="modalAllFilesManagementPaid"
                    :allFiles="allFilesPaid"
                    @triggDownloadFile="triggDownloadFilePaid"
                    @triggDownloadAllFiles="triggDownloadAllFilesPaid"
                >
                </modal-all-files-management>

                <c-set-b-f-category-name
                    ref="setCatNameModalPaid"
                    :budgetForm="budgetFormPaid"
                    :categoryFieldNames="categoryFieldNames"
                    :lng="lng"
                    :getCommonText="getCommonText"
                    @addItem="addItem"
                    @onCatNameChanged="onCatNameChanged"
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FormsHandlerMixinPaid from "./mixins/forms-handler-mixin-paid";
import Form03149Template1 from "./components/budget-forms-template1.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";
import Form02159Tab1 from './Form02_159_tab1.vue';
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form02_159',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        Form03149Template1, 
        ModalAllFilesManagement, 
        CBudgetFormsCopyData,
        CSetBFCategoryName,
        Form02159Tab1,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixinPaid],
    data() {
        return {
            form: {
                code: '02-159',
                name_ru: 'РАСЧЕТ РАСХОДОВ ПО ОПЛАТЕ РАБОТ И УСЛУГ, ОКАЗАННЫХ ФИЗИЧЕСКИМИ ЛИЦАМИ, ГОСУДАРСТВЕННЫМИ ПРЕДПРИЯТИЯМИ И ОРГАНИЗАЦИЯМ С ГОСУДАРСТВЕННЫМ УЧАСТИЕМ',
                name_kk: 'Жеке тұлғалар және акцияларының бақылау пакеттері мемлекетке тиесілі мемлекеттік кәсіпорындар, акционерлік қоғамдар және мемлекеттік қатысу үлестерінің мөлшерлері қатысушылардың жалпы жиналысының шешімін анықтауға мүмкіндік беретін жауапкершілігі шектеулі серіктестіктер көрсеткен жұмыстарға және көрсетілетін қызметтерге төлеу жүргізу бойынша шығыстарды есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            budgetFormPaid: [],
            header: null,
            files: null,
            filesPaid: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            isReportUploading: false,
            categoryFieldNames: {ru: "name_ru", kk: "name_kk"}
        };
    },
    mounted() {
        this.progress = 15;
    },
    watch: {
        selectAllPaid() {
            this.$refs.formTabPaid.setSelectAll(this.selectAllPaid);
        },

        selectAll() {
            this.$refs.formTab.setSelectAll(this.selectAll);
        },
    },
    methods: {
        addItem(nameRu, nameKk) {
            if (this.isNotSavedCatExist) return;
            const item = { id: -1 };
            this.itemUpdate(item, nameRu, nameKk);
            if (this.activeTabIndex === 0) this.budgetForm.push(item);
            if (this.activeTabIndex === 1) this.budgetFormPaid.push(item);
        }, // добавление новой строки в таблицу категорий

        itemUpdate(item, nameRu, nameKk) {
            this.$set(item, 'name_ru', nameRu);
            this.$set(item, 'name_kk', nameKk);
            this.$set(item, 'code', this.uuidv4());
            this.$set(item, 'amount', 0);
            this.$set(item, 'price', 0);
            this.$set(item, 'total', 0);
            this.$set(item, 'newCat', true);
            this.$set(item, 'nameUpdating', false);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'oldName', nameRu);
            this.$set(item, 'oldName_kk', nameKk);
        }, // заполнение полей при добавлении новой категории


        async loadCategoryData() {
            this.load = true;
            this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.fillBudgetForm(values, this.budgetForm)
                    }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCategoryData()', error.toString());
            }
            this.load = false;
        },

         async loadCategoryDataPaid() {
            this.load = true;
            this.budgetFormPaid.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat-paid/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.fillBudgetForm(values, this.budgetFormPaid)
                    }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCategoryDataPaid()', error.toString());
            }
            this.load = false;
        },

        fillBudgetForm(values, budgetForm) {
            values.forEach((val, i) => {
                const avgPrice = this.safeDecimal(val.avg_price).toDecimalPlaces(2).toNumber();
                const sumTotal = this.safeDecimal(val.sum_total).div(1000).toDecimalPlaces(3).toNumber();
                const item = {
                    id: i,
                    name_ru: val.name_ru,
                    name_kk: val.name_kk,
                    code: val.category_id,
                    category_id: val.category_id,
                    amount: val.sum_amount,
                    price: avgPrice,
                    total: sumTotal,
                    newCat: false,
                    oldName: val.name_ru,
                    oldName_kk: val.name_kk,
                    nameUpdating: false,
                    files: this.getUnicFilesArr(val),
                    itemToDelete: false,
                };
                this.$set(item, 'unit', null);
                budgetForm.push(item);
            });
            budgetForm.sort((a, b) => {
                const nameField = this.categoryFieldNames[this.lng];
                const aField = a[nameField] ? a[nameField].toLowerCase() : '';
                const bField = b[nameField] ? b[nameField].toLowerCase() : '';
                return aField > bField ? 1 : -1
            });
        },

        fillDataForSave(item, row) {
            this.$set(item, 'name_ru', this.currCategory.name_ru);
            this.$set(item, 'name_kk', this.currCategory.name_kk);
            this.$set(item, 'code', this.currCategory.code);
            this.$set(item, 'amount', parseFloat(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        fillDataForSavePaid(item, row) {
            this.$set(item, 'name_ru', this.currCategoryPaid.name_ru);
            this.$set(item, 'name_kk', this.currCategoryPaid.name_kk);
            this.$set(item, 'code', this.currCategoryPaid.code);
            this.$set(item, 'amount', parseFloat(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров
        
        prepareData(val) {
                this.$set(val, 'good_type', val.name_ru);
        },

        input12(item, field, value) {
            if (parseInt(value) > 12) {
                this.$set(item, field, 0);
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('not_more_12'));
            } else {
                this.$set(item, field, value);
            }
        }, // проверка value>12

        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        getFiles(data) {
            this.files = data;
        },
        getFilesPaid(data) {
            this.filesPaid = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        noAbc: function (event) {
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        getCodeFieldName() {
            return 'code';
        }, // при необходимости заменить в родителе

        onCatNameChanged(row) {
            this.$set(row, 'rowToSave', true);

            if (this.activeTabIndex === 0) this.onNameChanged(row, this.categoryFieldNames);
            if (this.activeTabIndex === 1) this.onNameChangedPaid(row, this.categoryFieldNames);
        }
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum = this.selfSum(row.total, sum);
            }
            return Math.ceil(sum);
        },
        totalPaid() {
            let sum = 0;
            for (const row of this.budgetFormPaid) {
                sum = this.selfSum(row.total, sum);
            }
            return Math.ceil(sum);
        },
        isBreadcrumbsShown() {
            const isAtBudgTabShown = this.activeTabIndex === 0 && this.categoryMode;
            const isAtPaidTabShown = this.activeTabIndex === 1 && this.categoryModePaid;
            return isAtBudgTabShown || isAtPaidTabShown;
        }
    }
};
</script>

<style scoped>
    .error input {
        padding-left: 25px;
    }
    .filter-actions-flex {
        display: flex;
    }
    .table-plus {
        border: none;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }
    .budget-form_tabs {
        display: none;
    }
</style>